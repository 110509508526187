import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import SEO from "../components/seo";
import MyAdsContentInner from '../components/myAdsContentInner';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/sv';
import i18nMessages from '../i18n/sv.json';
import { FormattedMessage, injectIntl } from 'react-intl';


class MyAdsContent extends React.Component {

 	render() {
		if (this.props.loggedIn) {
        	return (
        		<div>
	        		<SEO 
                		title={this.props.totalUnread == 0 ? 
		                	this.props.intl.formatMessage({ id: 'myAds' }) : 
		                	this.props.totalUnread + " " + this.props.intl.formatMessage({ id: 'dM' })}
		                pagePath="/my-ads"
		            />
					<MyAdsContentInner />
				</div>
			);

        } else {
    		return(
				<div className="chatPageWrapper page">
					<SEO 
		                title={this.props.intl.formatMessage({ id: 'myAds' })}
			            pagePath="/my-ads"
	            	/>
					<div className="errorText"><FormattedMessage id="loginShowAds" /> <br /> <FontAwesomeIcon icon={faHandPointUp} /></div>
				</div>
			);
        }
	}
}

function mapStateToProps(state) {
	const { loggedIn, totalUnread, initialLoader } = state
	return { loggedIn: loggedIn,
			 totalUnread: totalUnread,
			 initialLoader: initialLoader }
}


export default injectIntl(connect(
	mapStateToProps
)(MyAdsContent));