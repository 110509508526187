import React from "react"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVenus, faMars, faGenderless, faCrown, faHeart, faCaretUp, faAdjust, faCircle, faChessQueen } from '@fortawesome/free-solid-svg-icons';
import { faFrownOpen, faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import anonUser from '../../static/anon_user.png';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import InfoModal from "./infoModal";
import MyAdItem from "./myAdItem";
import { FormattedMessage, injectIntl } from 'react-intl';

class MyAdsContentInner extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			errorText: '',
			isDeleting: false,
			isLoading: true,
			showInfoModal: false,
			infoModalText: '',
			userAds: []
		};


		this.closeInfoModal = this.closeInfoModal.bind(this);
		this.showInfoModal = this.showInfoModal.bind(this);
		this.getUserAds = this.getUserAds.bind(this);
		this.removeAd = this.removeAd.bind(this);
	}

	componentDidMount() {
		this.getUserAds();
	}

 	getUserAds() {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'getUserAds';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						userAds: result.userAds,
						isLoading: false
					});
				} else {
					if (result.errorCode == 101) { 
						window.location.reload();
					}
					
					this.setState({
						infoModalText: this.props.intl.formatMessage({ id: 'errorTryLater' }),
						showInfoModal: true,
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					infoModalText: this.props.intl.formatMessage({ id: 'errorTryLater' }),
					showInfoModal: true,
					isLoading: false
				});
			}
		);

		return false;
 	}

 	removeAd(adId) {
 		this.setState({
			isDeleting: true
		});

 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'removeAd';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				adId: adId
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						userAds: this.state.userAds.filter(userAd => userAd.id != adId),
						isLoading: false,
						isDeleting: false
					});
				} else {
					if (result.errorCode == 101) { 
						window.location.reload();
					}
					
					this.setState({
						infoModalText: this.props.intl.formatMessage({ id: 'tryLater' }),
						showInfoModal: true,
						isLoading: false,
						isDeleting: false
					});
				}
			},
			error => {
				this.setState({
					infoModalText: this.props.intl.formatMessage({ id: 'tryLater' }),
					showInfoModal: true,
					isLoading: false,
					isDeleting: false
				});
			}
		);

		return false;
 	}


	showInfoModal(text) {
		this.setState({
			showInfoModal: true,
			infoModalText: text
		});
	}

	closeInfoModal(e) {
		this.setState({
			showInfoModal: false
		});
	}



 	render() {
 		let ads = [];
 		let userAds = this.state.userAds;

 		if (userAds != undefined) {
 			for (let i = 0; i < userAds.length; i++) {
 				let currentAd = userAds[i];

 				// Set the formatted seeking and gender names.
				let seeking = this.props.intl.formatMessage({ id: 'other' });
				if (currentAd.seeking == 'man') {
					seeking = this.props.intl.formatMessage({ id: 'man' });
				} else if (currentAd.seeking == 'woman') {
					seeking = this.props.intl.formatMessage({ id: 'woman' });
				}

				ads.push(
					<MyAdItem removeAd={this.removeAd} key={'ad'+currentAd.id} adId={currentAd.id}
						seeking={seeking} title={currentAd.title} text={currentAd.text} isDeleting={this.state.isDeleting} />
 				);
 			}
 		}

    	return (
			<div className="pageWrapper page">
				<div className="h-100 chatWrapper">

					<div className="row accountContent">

						<div className="col-xl-6 chat">
								
								<div className={this.state.isLoading ? 'dcLoader medium' : 'hidden'} />

								<div className={this.state.isLoading ? 'card-body msgCardBody visibilityHidden' : 'card-body msgCardBody'}>
									
									<div className="">

										<InfoModal show={this.state.showInfoModal} closeInfoModal={this.closeInfoModal} body={this.state.infoModalText} />
										
										{this.state.errorText == '' && !this.state.isLoading ? 
												
											<div className="page-content">

												<div className="">
													
													<div className="row">
														{ads.length <= 0 ? 
															<div className="errorText"><FormattedMessage id="noAdsFound" /></div>
														 	: 
														 	ads
														}
														
													</div>
												</div>
											</div>

										 : null
										}

										{this.state.errorText != '' &&  !this.state.isLoading ? 
											<div className="errorText">{this.state.errorText} <br /> <FontAwesomeIcon icon={faFrownOpen} /></div>
										 : null
										}

									</div>
									
								</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	},
	dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(MyAdsContentInner));