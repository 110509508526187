import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import anonUser from '../../static/anon_user.png';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';



export default function MyAdItem(props) {
	

	return (
		<figure className="myAdItem adItem">
			<div className="adItemInner">
				<div className="adItemHeader">
					<div className="adItemDetails">
						<div className="adItemTitle">{props.title}</div>
						<div className="adItemCategory">
							<FormattedMessage id="seeking" /> <span className="grid-category-seeking">{props.seeking}</span>
						</div>
					</div>
				</div>
				<div className="grid-item-description">
					<p className="adItemDescInner">{props.text}</p>
				</div>
				<Button onClick={() => props.removeAd(props.adId)} disabled={props.isDeleting} variant="danger">
					<FormattedMessage id="remove" />
				</Button>
			</div>
		</figure>
	);
	
}



